  const scrollSmoothToBottom = function(className) {

    window.requestAnimationFrame(function () {
        var div = document.getElementsByClassName(className)[0];
        if (div) {
            div.scrollTop += div.scrollHeight;
        }
    });
  };

export { scrollSmoothToBottom };