import React from 'react';
import { FontIcon, Icon } from '@fluentui/react/lib/Icon';

const GroupSessionDisplayName = (props) => {
    
     const ContactIcon = () => <Icon iconName="Contact" />;
    

    return (
        <div className="input">
            <span className="field-icon">
                <span><ContactIcon aria-label="ContactIcon" iconName="ContactIcon" /></span>
            </span>
            <input placeholder="Display Name" type="text" autoComplete="off" onChange={event => props.onDisplayNameEntry(event.target.value)} required />
          
        
        </div>
    );

};

export default GroupSessionDisplayName;