import axios from 'axios';
import { SERVICE_BASE, ONEC_API_OPTIONS } from '../globals';

export async function ReportCallInLobby(consultationID, operatingSystem, browser,ipAddress,isGroupCall)
{
    var status = {};
    status.consultationId = consultationID;
    status.guestUserOs = operatingSystem;
    status.guestUserBrowser = browser;
    status.guestUserIpAddress =ipAddress;
    status.guestUserAgentData = window.navigator.userAgent;
    status.isGroupCall = isGroupCall;


      var options = ONEC_API_OPTIONS;
        options.validateStatus = function (status) {
            return (status >= 200 && status < 300); // Reject only if the status code is not 2xx
    };
    
     await axios.put(`${SERVICE_BASE}/callstatus/callinlobby`,status, ONEC_API_OPTIONS);
}


export async function ReportCallInMeeting(consultationID,isGroupCall)
{
    try
    {
        var options = ONEC_API_OPTIONS;
        options.validateStatus = function (status) {
            return (status >= 200 && status < 300); // Reject only if the status code is not 2xx
        };

        var status = {};
        status.consultationId = consultationID;
        status.isGroupCall = isGroupCall;
        const response = await axios.put(`${SERVICE_BASE}/callstatus/callinmeeting`, status, options);
    }
    catch(error)
    {
        console.log(error);
    }
};

export async function ReportCallDisconnected(consultationID,isGroupCall)
{
    var status = {};
    status.consultationId = consultationID;
    
      var options = ONEC_API_OPTIONS;
        options.validateStatus = function (status) {
            return (status >= 200 && status < 300); // Reject only if the status code is not 2xx
    };   
    status.isGroupCall = isGroupCall;

    const response = await axios.put(`${SERVICE_BASE}/callstatus/calldisconnected`, status, ONEC_API_OPTIONS).catch(error => 
    {
        console.log(error);
    });
}

export async function ReportCallInProgress(consultationID, isGroupCall)
{
    var options = ONEC_API_OPTIONS;
    options.validateStatus = function (status) {
        return (status === 200 || status === 404); // Reject only if the status code is greater than or equal to 500
    };
    
    var status = {};
    status.consultationId = consultationID;
    status.isGroupCall = isGroupCall;

    const url = isGroupCall ? `${SERVICE_BASE}/callstatus/groupcallinprogress` : `${SERVICE_BASE}/callstatus/callinprogress`;
    
      let response =  await axios.put(url, status, ONEC_API_OPTIONS);
    
    
 if (response.status === 404)
    {
        return false;
        }
        return true;
    
   
}