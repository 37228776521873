import React, { useEffect, useState } from 'react';
import { ChatClient, ChatThreadClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { Modal } from '@fluentui/react';
import ChatMessage from './ChatMessage'
import { DefaultButton } from '@fluentui/react';
import { ReportCallInMeeting, ReportCallInProgress } from '../utils/reportCallStatus';
import RecordingMessage from './RecordingMessage';


const TextOnlyConnectedCall = (props) => {

    let chatClient;
  
  let chatThread;
  let acsEndpoint = "https://oneconsultationacspoc.communication.azure.com";
  
  const [chatMessages, setChatMessage] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatThreadClient, setChatThreadClient] = useState();
    
    
  useEffect(() => {
  
    const reportInMeeting= async ()=>{
      await ReportCallInMeeting(props.consultationId,props.isGroupCall);
    }

    const otherFuncs= async ()=>{
      chatClient = new ChatClient(acsEndpoint, new AzureCommunicationTokenCredential(props.token));
    
      let threadClient = await chatClient.getChatThreadClient(props.chatThreadId);    
      var response = await threadClient.sendMessage({ content: "[Service Message] The Web Patient user is able to receive chat messages sent to this meeting. Messages will not indicate the identity of the person sending them; all messages will appear from a common source." });
      setChatThreadClient(threadClient);
      console.log(response);
  
      await chatClient.startRealtimeNotifications();
      chatClient.on("chatMessageReceived", (e) => {
        if (e.message.indexOf("[Service Message]") > 0) return; //don't do anything web-side with service messages
        if (e.type == "RichText/Media_CallRecording") return; //don't do anything with chat messages about recording happening
        setChatMessage(chatMessages => [...chatMessages, e]);    
        scrollSmoothToBottom("chatContainer");
        props.onNewMessageReceived();
      });
    }

    try {

      reportInMeeting();
     const timer = setInterval(() => {
        checkCallStillValidAndUpdate();
    }, 30000);
    otherFuncs();
    //cleanup
    return () => {
        clearInterval(timer);
    };


    }
    catch (error) {
      console.log(error);
    }
  }, []);
  
    const checkCallStillValidAndUpdate = async () => {
        var validCheck = await ReportCallInProgress(props.consultationId, props.isGroupCall);
        if (!validCheck)
        {
            //call no longer valid, disconnect
            props.call.hangUp();
            window.alert("You call has been disconnected.");
        }
      //   else{
      //     setReJoinSessionDataExpiry();
      // }
    };
  
  const OnNewMessage = (async () => {    
      await chatThreadClient.sendMessage({ content: newMessage });
      setNewMessage('');
    });
  
  const OnKeyUp = (async (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      await OnNewMessage();
    }
  });

  function scrollSmoothToBottom(id) {
    window.requestAnimationFrame(function () {
      var div = document.getElementById(id);
      if (div) div.scrollTop = div.scrollHeight;
    });
  };

const onDisconnect = () => {
        if (window.confirm("Are you sure you want to end this chat consultation?")) {
                //insights.trackEvent('DisconnectButtonClick');
            props.call.hangUp();
               
            }
    }



    return (
      <>
<RecordingMessage call={props.call} portalSettings={props.portalSettings}></RecordingMessage>

          <div className="container">  
        <div className="ChatAndSendContainer">
          
            <div id="chatContainer" className="ChatContainer">
              
{ chatMessages.length === 0 ? (<div className="NoChatsYet">No Chats Yet</div>) : (                
                chatMessages.map(message => <ChatMessage id={message.id} key={message.id} message={message} />)
            )}
            
            </div>  
            { chatThreadClient ? 
            (
              
            <div className="ChatSendBox">
              <input className="ChatSendTextInput" type="text" placeholder="Type a new message" id="newMessageText" value={newMessage} onKeyUp={e=>OnKeyUp(e)} onInput={e=>setNewMessage(e.target.value)}></input>
                        <DefaultButton className="ChatSendButton" toggle text="Send" onClick={OnNewMessage} />
                        <DefaultButton className="ChatDisconnectButton" text="Disconnect" onClick={onDisconnect} allowDisabledFocus />
                </div>
                
              ) : (
              <></>
              )}
          </div>
          </div>
       </>
       
    );
}

export default TextOnlyConnectedCall;