import { VideoStreamRenderer } from "@azure/communication-calling";

export async function displayLocalVideo(stream)
    {        
	let renderer;
	let renderVideoObject = document.getElementById('local-render');
	if (!renderVideoObject)
	{
		console.log("No local-render object exists");
		let newLocalRenderDiv = document.createElement("div");
		newLocalRenderDiv.id = "local-render";
		newLocalRenderDiv.className = "local-render";
		document.body.appendChild(newLocalRenderDiv);
renderVideoObject = document.getElementById('local-render');
		}
	if (renderVideoObject) {
		renderer = new VideoStreamRenderer(stream);
		const view = await renderer.createView();
		renderVideoObject.appendChild(view.target);
	}
}

