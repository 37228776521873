import { Icon, mergeStyleSets } from '@fluentui/react';
import { ControlBarButton } from '@azure/communication-react';

export const CUSTOM_BUTTON_OPTIONS = {
  MAX_PRIMARY_DESKTOP_CUSTOM_BUTTONS: 3,
  MAX_PRIMARY_MOBILE_CUSTOM_BUTTONS: 1,
  MAX_SECONDARY_DESKTOP_CUSTOM_BUTTONS: 2
};

export const generateCustomCallControlBarButton = (
  onFetchCustomButtonProps,
  displayType
) => {
  const allButtonProps = onFetchCustomButtonProps?.map((callback) => callback({ displayType }));

  return {
    primary: generateCustomControlBarButtons('primary', allButtonProps),
    secondary: generateCustomControlBarButtons('secondary', allButtonProps),
    overflow: generateCustomControlBarButtons('overflow', allButtonProps)
  };
};

const generateCustomControlBarButtons = (
  placement,
  customButtons
) =>
  customButtons
    ? customButtons
        .filter((buttonProps) => buttonProps.placement === placement)
        .map((buttonProps, i) => (internalProps) => {
          // onRenderButton is a custom prop that can be passed to override the default rendering of the button
          // This is useful for custom buttons that need to render more than just an icon and label and is used
          // for CallWithChat button notification badge. However, onRenderButton is not a public prop and is
          // not documented in the API. This is a temporary solution and will need to be revisited.
          if ('onRenderButton' in buttonProps) {
            return (buttonProps['onRenderButton'])(internalProps);
          }
          return (
            <ControlBarButton
              ariaDescription={buttonProps.strings?.ariaDescription ?? internalProps.ariaDescription}
              ariaLabel={
                buttonProps.strings?.ariaLabel ?? buttonProps.strings?.tooltipContent ?? internalProps.ariaLabel
              }
              disabled={buttonProps.disabled ?? internalProps.disabled}
              id={buttonProps.id ?? internalProps.id}
              key={`${buttonProps.placement}_${i}`}
              onClick={buttonProps.onItemClick ?? internalProps.onClick}
              onRenderIcon={() => (
                <Icon iconName={buttonProps.iconName ?? internalProps.iconProps?.iconName ?? 'DefaultCustomButton'} />
              )}
              showLabel={buttonProps.showLabel ?? internalProps.showLabel}
              styles={mergeStyleSets(internalProps.styles)}
              strings={buttonProps.strings}
            />
          );
        })
    : [];

/** @private */
const generateCustomDrawerButtons = (
  placement,
  customButtons
) =>
  customButtons
    ? customButtons
        .filter((buttonProps) => buttonProps.placement === placement)
        .map(
          (buttonProps, i) => ({
            ...buttonProps,
            disabled: buttonProps.disabled,
            iconProps: { iconName: buttonProps.iconName ?? 'DefaultCustomButton', styles: { root: { lineHeight: 0 } } },
            id: buttonProps.id,
            itemKey: `${buttonProps.placement}_${i}`,
            onItemClick: buttonProps.onItemClick,
            text: buttonProps.strings?.label
          })
        )
    : [];
    
export const generateCustomCallDrawerButtons = (
  onFetchCustomButtonProps,
  displayType
) => {
  const customButtons = onFetchCustomButtonProps?.map((callback) => callback({ displayType }));
  return {
    primary: generateCustomDrawerButtons('primary', customButtons),
    secondary: generateCustomDrawerButtons('secondary', customButtons),
    overflow: generateCustomDrawerButtons('overflow', customButtons)
  };
};

export const generateCustomCallDesktopOverflowButtons = (
  onFetchCustomButtonProps,
  displayType
) => {
  const customButtons = onFetchCustomButtonProps?.map((callback) => callback({ displayType }));
  return {
    primary: generateCustomDrawerButtons('primary', customButtons).map(drawerMenuItemToContextualMenuItem),
    secondary: generateCustomDrawerButtons('secondary', customButtons).map(drawerMenuItemToContextualMenuItem),
    overflow: generateCustomDrawerButtons('overflow', customButtons).map(drawerMenuItemToContextualMenuItem)
  };
};

export const drawerMenuItemToContextualMenuItem = (item) => ({
  ...item,
  key: item.itemKey,
  onClick: item.onItemClick
    ? (ev) => {
        item.onItemClick?.(ev);
      }
    : undefined,
  subMenuProps: item.subMenuProps
    ? {
        items: item.subMenuProps.map(drawerMenuItemToContextualMenuItem)
      }
    : undefined
});

export const onFetchCustomButtonPropsTrampoline = (
  options
) => {
  let response = undefined;
  response = options?.onFetchCustomButtonProps;
  return response;
};
