import React, { useEffect, useState } from 'react';
import { Dropdown } from '@fluentui/react';
import { FontIcon, Icon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';


const DeviceChoices = (props) => {

    const [selectedVideoDeviceKey, setSelectedVideoDeviceKey] = useState();
    const [selectedAudioDeviceKey, setSelectedAudioDeviceKey] = useState();
    
    const BeforeYouStartIcon = () => <Icon iconName="WaitlistConfirm" />;

      const iconClass = mergeStyles({
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '0px',
});

    useEffect(() => {
        async function set() {
             setSelectedVideoDeviceKey(props.videoDevices[0].key);
             setSelectedAudioDeviceKey(props.audioDevices[0].key);
            await setLocalVideo(props.videoDevices[0].key,props.audioDevices[0].key, props.videoDevices[0].text);
        }
        set();
    }, []);
    
    const  setLocalVideo = async (videoDevice, audioDevice, selectedVideoDeviceText) =>
    {
        if (videoDevice != null) props.onVideoDeviceUpdated(videoDevice);
        if (audioDevice != null) props.onAudioDeviceUpdated(audioDevice);

        const videoElement = document.getElementById('pre-connect-deviceChoice-video');
        videoElement.srcObject = null;
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
            track.stop();
        });
        };
        const constraints = {
            audio: audioDevice != null ? { deviceId: audioDevice } : true,
            video: videoDevice != null ? { deviceId: videoDevice } : { facingMode: "user" }
        };
        try {
            await navigator.mediaDevices.getUserMedia(constraints);        //this is done with audio and video in order to ask for permission
            constraints.audio = false;
            let streamForLocalFeed = await navigator.mediaDevices.getUserMedia(constraints);
            window.stream = streamForLocalFeed; // make stream available to console
            videoElement.srcObject = streamForLocalFeed;

            if (videoDevice == null) // first time load, so set the drop down to the selected camera
            {
                setSelectedVideoDeviceKey(props.videoDevices[0].key);
                props.onVideoDeviceUpdated(props.videoDevices[0].key);
            }
            if (audioDevice == null)
            {
                setSelectedAudioDeviceKey(props.audioDevices[0].key);
                props.onAudioDeviceUpdated(props.audioDevices[0].key);
            }
            
        }
        catch (ex)
        {
            window.alert("Problem activating device " + selectedVideoDeviceText + ". Please choose another camera.");
        }

        }

   const onChangeVideoDevice = (e,item) =>
    {
       setSelectedVideoDeviceKey(item.key);
       setLocalVideo(item.key, selectedAudioDeviceKey, item.text);
    }

    // const onChangeAudioDevice = (e,item) =>
    // {
    //    setSelectedAudioDeviceKey(item.key);
    // }

    return (
<>

        
 <div className="col call-setup">
                        <div className="inner">
                            <div className="inner__body">
                                <span><BeforeYouStartIcon aria-label="WaitlistConfirm" iconName="WaitlistConfirm" className={iconClass} /></span>
                                <h2>Before you start...</h2>
                        <video id="pre-connect-deviceChoice-video" playsInline autoPlay></video>
                        <p>Make sure you can see yourself in the video feed. You can change cameras if you need to. Make sure you're in a quiet, well-lit place where you won't be disturbed.</p>
                       
                    </div>
                      <Dropdown
                     placeholder='Select a video device'
                     label='Change Camera: '
                     disabled={props.videoDevices.length === 0}
                     options={props.videoDevices}
                     selectedKey={selectedVideoDeviceKey}
                     onChange={onChangeVideoDevice}
                 />
                            <div className="inner__cta">
                                <a href="https://docs.oneconsultation.net/patient-guidance.html" target="_blank" rel="noreferrer" className="begin-test"><span className="btn-inner">Video Consultation Tips<span className="tooltip"><span className="tooltip__btn"><i className="icon-info"></i></span><span className="tooltip__content"><p>First time here? Click for advice on getting set up for video consultation.</p></span></span></span></a>
                            </div>
                        </div>
                    </div>

</>

        // <div>            
        //     <video id="pre-connect-deviceChoice-video" playsInline autoPlay></video>
        //     <div style={{ width: '16rem' }}>
        //         <Dropdown
        //             placeholder='Select a video device'
        //             label='Camera'
        //             disabled={props.videoDevices.length === 0}
        //             options={props.videoDevices}
        //             selectedKey={selectedVideoDeviceKey}
        //             onChange={onChangeVideoDevice}
        //         />
        
        //         {/* <Dropdown
        //             placeholder='Select an audio device'
        //             label='Microphone'
        //             disabled={props.audioDevices.length === 0}
        //             options={props.audioDevices}
        //             selectedKey={selectedAudioDeviceKey}
        //             onChange={onChangeAudioDevice}
        //         /> */}
        //     </div>
        // </div>
    );
};

export default DeviceChoices;