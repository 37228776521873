import React, { useEffect } from 'react';
import { ReportCallDisconnected } from '../utils/reportCallStatus';

const DisconnectedCall = (props) => {

      useEffect(() => {
        ReportCallDisconnected(props.consultationId,props.isGroupCall);
          window.location.replace(props.disconnectUrl);
    }, []);

    return (
        <>
            <span className="message">The call has ended. <a href={props.disconnectUrl}>Continue</a></span>        
            
        </>
    );
}

export default DisconnectedCall;