import React from 'react';

const ConfirmableMessage = (props) => (
    <>
        {props.showConfirmableMessage ? (
        
             <div className="input">
                <label>
                    <input type="checkbox" id="confirmableMessageTick" onChange={event => props.onConfirmableMessageChange(event.target.checked)} />
                    <label className="confirmationMessage" htmlFor="confirmableMessageTick" dangerouslySetInnerHTML={{ __html: props.confirmationMessageText }}></label>
                </label>
            </div>

        ) : <></>
        }

        </>


    );

export default ConfirmableMessage;