import { DefaultButton, PrimaryButton } from '@fluentui/react';
import {  Modal } from '@fluentui/react';


const DisconnectModal = (props) => {
   
    return (
        <>
             <Modal closeButtonAriaLabel="Close" titleAriaId="disconnectModal" containerClassName="" isOpen={props.showDisconnectModal} isBlocking={true} >
          <div className="DisconnectContainer">
            <h2>This will end your consultation.</h2>
              
                    <div className="DisconnectButtons">
                    <DefaultButton className="DisconnectOptionButton" text="Return to Consultation" onClick={props.hideDisconnectModal} />     
                    <DefaultButton className="DisconnectOptionButton" text="Copy chat transcript" onClick={props.copyChatClick} />
                    <PrimaryButton className="DisconnectOptionButton" text="End Consultation" onClick={props.onHangUp} />
                 </div>
                  
              <></>
              
          </div>
           </Modal>
                        
        </>
    );
};

export default DisconnectModal;