import React, { useState, useEffect } from 'react';
import PreConnectionContainer from './preconnection/PreConnectionContainer';
import JoinLinkPreConnectionContainer from './preconnection/JoinLinkPreConnectionContainer';
import ConnectionContainerUIComponent from './ConnectionContainerUIComponent';

const PortalContainer = (props) => {
    const handleReadyToConnect = (newConsultationResponse, disconnectUrl, videoKey, audioKey, imageUrl, portalTitle, waitingRoomLayout, patientDisplayName,isGroupCall ) => {
        
        setConsultationResponse(newConsultationResponse);
        setToken(newConsultationResponse.conferenceNode);
        setMeeting(newConsultationResponse.joinUrl);
        setChatThreadId(newConsultationResponse.chatThreadId);
        setConferenceId(newConsultationResponse.conferenceId);
       
        setDisconnectUrl(disconnectUrl);
        setImageUrl(imageUrl);
        setPortalTitle(portalTitle);
        setSelectedVideoDeviceKey(videoKey);
        setSelectedAudioDeviceKey(audioKey);
        setWaitingRoomLayout(waitingRoomLayout);
        setPatientDisplayName(patientDisplayName);
        setIsGroupCall(isGroupCall);
        setReadyToConsult(true);
        
    };
    
    const handleSetRoomTextOnly = () => {
        setTextOnlyRoom(true);        
    }
   const getYear=()=> {
        return new Date().getFullYear();
    }
    const [readyToConsult, setReadyToConsult] = useState(false);
    const [token, setToken] = useState("");
    const [meeting, setMeeting] = useState("");
    const [conferenceId, setConferenceId] = useState("");
    const [chatThreadId, setChatThreadId] = useState("");
    const [disconnectUrl, setDisconnectUrl] = useState("");
    const [selectedVideoDeviceKey, setSelectedVideoDeviceKey] = useState();
    const [selectedAudioDeviceKey, setSelectedAudioDeviceKey] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [portalTitle, setPortalTitle] = useState();
    const [textOnlyRoom, setTextOnlyRoom] = useState(false);
    const [waitingRoomLayout, setWaitingRoomLayout] = useState();
    const [patientDisplayName, setPatientDisplayName] = useState("");
    const [isGroupCall, setIsGroupCall] = useState();
    const [consultationResponse, setConsultationResponse] = useState();

    return (
        <>

                    {(!readyToConsult && !props.isJoinLink) ? (
                                <PreConnectionContainer isPrepopulated={props.isPrepopulated} portalSettings={props.portalSettings} consultationRequest={props.consultationRequest} onReadyToConnect={handleReadyToConnect} onSetRoomTextOnly={handleSetRoomTextOnly} videoDevices={props.videoDevices} audioDevices={props.audioDevices}></PreConnectionContainer>
                    ) : <></>}

                    {(!readyToConsult && props.isJoinLink === true) ? (
                            <JoinLinkPreConnectionContainer portalSettings={props.portalSettings} consultationRequest={props.consultationRequest} onReadyToConnect={handleReadyToConnect} onSetRoomTextOnly={handleSetRoomTextOnly} videoDevices={props.videoDevices} audioDevices={props.audioDevices}></JoinLinkPreConnectionContainer>
                              
                    ) : <></>}

                    {(readyToConsult) ? (
                               <ConnectionContainerUIComponent consultationRequest={props.consultationRequest} token={token} teamsMeeting={meeting} 
                               consultationResponse={consultationResponse}
                               disconnectUrl={disconnectUrl} chatThreadId={chatThreadId} videoKey={selectedVideoDeviceKey} audioKey={selectedAudioDeviceKey} 
                               portalLogoUrl={imageUrl } portalTitle={portalTitle} textOnlyRoom={textOnlyRoom} waitingRoomLayout={waitingRoomLayout} 
                               patientDisplayName={patientDisplayName} consultationId={conferenceId} isGroupCall={isGroupCall} portalSettings={props.portalSettings} ></ConnectionContainerUIComponent>
                    ) : <></>}

            <footer>
            <div className="text-center">
                <p>&copy; Copyright {getYear()} Powered by Modality Systems.</p>
            </div>
        </footer>
 
        </>

    );

};

export default PortalContainer;