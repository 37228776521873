import React from 'react';
import { DefaultButton } from '@fluentui/react';

const ErrorConnectingCall = (props) => {

          
    const retry = () => {
        window.location.reload();
    };

    return (
        <>
            <h2 className="service-message">There was a problem. We weren't able to connect you to your consultation. Please try again.            
                 <DefaultButton text="Retry" onClick={retry} />
            </h2>
            <h6>{props.errorMessage}</h6>

     
            
        </>
    );
}

export default ErrorConnectingCall;