import React, { useEffect, useState } from 'react';
import { SERVICE_BASE, ONEC_API_OPTIONS } from '../../globals';
import QuestionList from './QuestionList';
import axios from 'axios';

import DeviceChoices from './DeviceChoices';
import TextOnlyRoom from './TextOnlyRoom';

import { v4 as uuidv4} from 'uuid';
import ReconnectBanner from '../ReconnectBanner';


const PreConnectionContainer = (props) => {
  const [portalSettings, setPortalSettings] = useState({});
  //const [portalSettingCallStatusText, setPortalSettingCallStatusText] = useState("");
  const [isSiteUnkown, setIsSiteUnknown] = useState(false);
  const [showGatedEntry, setShowGatedEntry] = useState(false);
  const [gatedEntryLastCheckedTime, setGatedEntryLastCheckedTime] = useState("");
  const [selectedVideoDeviceKey, setSelectedVideoDeviceKey] = useState("");
  const [selectedAudioDeviceKey, setSelectedAudioDeviceKey] = useState("");
  const [gatedRequestId, setGatedRequestId] = useState("");


  useEffect(() => {
    if (props.portalSettings.isTextOnly) {
      props.onSetRoomTextOnly();
    }
  }, []);

  const createNewConsultation = async (data) => {
    try {
      const response = await axios.post(`${SERVICE_BASE}/consultations/CreateNewConsultation`, data, ONEC_API_OPTIONS);
      console.log(response);
      return response;
    }
    catch (ex) {
      console.log(ex)      
    }
  };

  const joinGroupSession = async (data) => {
    try {
        var options = ONEC_API_OPTIONS;
        options.validateStatus = function (status) {
            return (status == 200 || status == 401); //anything else is an error
        }; 

      const response = await axios.post(`${SERVICE_BASE}/consultations/JoinGroupSession`, data, ONEC_API_OPTIONS);
      console.log(response);
      return response;
    }
    catch (ex) {
      console.log(ex)
      
    }
  };

  const handleError = (message) => {
    alert(message);
    window.location.reload();
  };

  const answersSubmitted = async (answers, accessCode, groupSessionDisplayName) => {
    console.log(answers);
    let questionsWithAnswers = props.portalSettings.questions;
    questionsWithAnswers.forEach(function (origQuestion) {
      let answerIndex = answers.findIndex((e) => e.rowKey === origQuestion.rowKey);
      origQuestion.questionanswer = answers[answerIndex].questionanswer;
    });
    
    let request = props.consultationRequest;
    if (!request) {
      request = {};
    }
    
    request.Questions = questionsWithAnswers;
    
    let createResponse;
    if (props.portalSettings.isGroup) {
      request.groupSessionPin = accessCode;

      if (props.portalSettings.gatedEntry) {
        let gatedEntryRequest = BuildGatedEntryRequest(groupSessionDisplayName, questionsWithAnswers);
        setShowGatedEntry(true);
        PollForGatedEntryUpdate(gatedEntryRequest, request);
      }
      else {
        createResponse = await joinGroupSession(request);
          HandleConsultationResponse(createResponse, groupSessionDisplayName);
      }
    }
    else {
      createResponse = await createNewConsultation(request);
       HandleConsultationResponse(createResponse, groupSessionDisplayName);
    }
  };

  const HandleConsultationResponse = (response, groupSessionDisplayName) => {
    if (!response) {
      handleError("An unexpected error has occured. Please try again.");
    };

    if (response.status == 401) {
      handleError("Incorect Access Code. Please try again.");
    }

    props.onReadyToConnect(response.data, props.portalSettings.disconnectURL, selectedVideoDeviceKey, selectedAudioDeviceKey, props.portalSettings.portalLogoUrl, props.portalSettings.portalTitle, props.portalSettings.consultationWaitingRoomLayout, props.portalSettings.isGroup ? groupSessionDisplayName : props.portalSettings.patientIdentifierText, props.portalSettings.isGroup);
  };

  const onVideoDeviceUpdated = (videoKey) => {
    setSelectedVideoDeviceKey(videoKey);  
  };

  const onAudioDeviceUpdated = (audioKey) => {
    setSelectedAudioDeviceKey(audioKey);
  };

  function BuildGatedEntryRequest(groupSessionDisplayName, questionsWithAnswers)  {
    let gatedEntryRequest = {};
    setGatedRequestId (uuidv4());
    gatedEntryRequest.requestId = gatedRequestId
    gatedEntryRequest.displayName = groupSessionDisplayName;
    gatedEntryRequest.questions = questionsWithAnswers;
    return gatedEntryRequest;
  };

  const PollForGatedEntryUpdate =  async (request, originalRequest) => {
    try {
      let response = await axios.post(`${SERVICE_BASE}/groups/signalwaitingforgroupsession`, request, ONEC_API_OPTIONS);

           
      if (response.status === 200 && response.data.status == 0) {
        FinishWithRequest();
        let createResponse = await joinGroupSession(originalRequest);
        HandleConsultationResponse(createResponse, request.displayName);
        setShowGatedEntry(false);
        return;
      } else if (response.status === 200 && response.data.status == 1) {
        var denyMessage = response.data.deniedReason ? "Reason: " + response.data.deniedReason : "";
        alert("You cannot join this session. " + denyMessage);
        FinishWithRequest();
        window.location.reload();
        return;
      }
      else if (response.status === 200) {
        var now = new Date();
        setGatedEntryLastCheckedTime(now.toLocaleTimeString());
        const timer = setTimeout(() => {
          PollForGatedEntryUpdate(request, originalRequest);
        }, 10000);
      };

    } catch (ex) {
      console.log(ex);
      const timer = setTimeout(() => {
        PollForGatedEntryUpdate(request, originalRequest);
      }, 10000);
    };
  }

  function FinishWithRequest() {
    axios.put(`${SERVICE_BASE}/groups/markdone?requestId=` + gatedRequestId, null,  ONEC_API_OPTIONS);         
  }

  return (
         
   <>
      {/* <pre>API: /settings/portal... {portalSettingCallStatusText}</pre><pre>Domain: {portalSettings.rowKey}</pre>   */}
      
      {(props.portalSettings.isSiteUnkown) ? (
        <div>Site Unknown</div>) : <></>
      }

   
       
         
      {(!props.portalSettings.isSiteUnkown) ? (
        <>
          <header className="no-nav">
            <div className="container">
              <div className="logo">
                <img src={props.portalSettings.portalLogoUrl} alt={props.portalSettings.portalTitle} className="logo"></img>
              </div>
            </div>
          </header>
          
          <main className="wb begin-consultation">
            
          <ReconnectBanner></ReconnectBanner>
           
            {(showGatedEntry) ?
              (<section>
                <div className="container container--sml">
                  <div className="col"></div>
                  <div className="col">
                    <h2 className="service-message">
                      You are in our waiting area. Your details are being verified. Please wait here to be let in - do not close or navigate away from this page. Last update: {gatedEntryLastCheckedTime}.
                    </h2>
                   
                    </div>
                  </div>
                
              </section>) : (
                <section>
                  <div className="container container--sml">
                    {(props.portalSettings.isTextOnly) ? (
                      <TextOnlyRoom></TextOnlyRoom>
                    ) :
                      (
                        <DeviceChoices videoDevices={props.videoDevices} audioDevices={props.audioDevices} onVideoDeviceUpdated={onVideoDeviceUpdated} onAudioDeviceUpdated={onAudioDeviceUpdated}   ></DeviceChoices>
                      )}
                
                    <div className="col setup-form">
                   
                      <div className="intro">
                        <h2>{props.portalSettings.portalTitle}</h2>
                        <p>{props.portalSettings.portalIntroExplainingText}</p>
                      </div>
                      <QuestionList isPrepopulated={props.isPrepopulated} questions={props.portalSettings.questions} showConfirmableMessage={props.portalSettings.showConfirmableMessage} confirmationMessageText={props.portalSettings.confirmableMessageText} beginConsultationText={props.portalSettings.beginConsultationText} onAnswersSubmitted={answersSubmitted} isGroupRoom={props.portalSettings.isGroup}></QuestionList>
                    </div>

                  </div>
                </section>)}

            


            <div className="bg">
              <span className="bubble"></span>
              <span className="bubble"></span>
              <span className="bubble"></span>
              <span className="bubble"></span>
            </div>
          </main>
              
        </>
      ) : <></>
      }
</>
   
      
  )

};

export default PreConnectionContainer;