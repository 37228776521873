import {
  usePropsFor, VideoGallery
} from '@azure/communication-react';

import { mergeStyles, Stack } from '@fluentui/react';
import React, { useMemo, useState, useEffect } from 'react';
import ControlbarComponent from './ControlbarComponent';

const CallingComponents = (props) => {

  const { isGroup, isAuditorium } = props;

  const [waitControlToRender, setwaitControlToRender] = useState(false);
  const [layout, setLayout] = useState("floatingLocalVideo");
  const [overflowPosition, setOverflowPosition] = useState("Responsive");
  useEffect(() => {
    const timer = setTimeout(() => {
      setwaitControlToRender(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const localVideoViewOptions = {
    scalingMode: 'Crop',
    isMirrored: true
  };

  const remoteVideoViewOptions = {
    scalingMode: 'Crop'
  };

  const onUserSetGalleryLayout = (layout) => {
    setLayout(layout);
  };

  const onUserSetGalleryOverflowPosition = (overflow) => {
    setOverflowPosition(overflow);
  };

  let videoGalleryProps = usePropsFor(VideoGallery);
  videoGalleryProps = useMemo(() => (isGroupAndAuditoriumRoom(isGroup, isAuditorium) ? filterRemoteParticipants(videoGalleryProps, isAuditorium) : filterRemoteParticipantsOther(videoGalleryProps, isGroup)
  ), [isGroup, isAuditorium, videoGalleryProps]);

  return (
    <Stack className={mergeStyles({ height: '100%' })}>
      <div className="video-wrapper">
        {videoGalleryProps && <VideoGallery  {...videoGalleryProps}
          localVideoViewOptions={localVideoViewOptions}
          remoteVideoViewOptions={remoteVideoViewOptions}
          layout={layout}
          overflowGalleryPosition={overflowPosition} />}
      </div>
      {waitControlToRender &&
        <ControlbarComponent
          hideChat={props.hideChat}
          onChatClicked={props.onChatClicked}
          copyChatClick={props.copyChatClick}
          chatMessagesCount={props.chatMessagesCount}
          showNewMessage={props.showNewMessage}
          onHangUp={props.onHangUp}
          layout={layout}
          handleLayout={onUserSetGalleryLayout} 
          userSetGalleryLayout = {layout}
          onUserSetOverflowGalleryPositionChange={onUserSetGalleryOverflowPosition}
          isAuditorium={isAuditorium}/>}
    </Stack>

  );
};

function isGroupAndAuditoriumRoom(isGroup, isAuditorium) {
  return isGroup === true && isAuditorium === true;
}

function filterRemoteParticipants(videoGalleryProps, isAuditorium) {
  if (isAuditorium) {
    return { ...videoGalleryProps, remoteParticipants: videoGalleryProps.remoteParticipants.filter(x => x.displayName != 'Web Patient' && !x.displayName?.startsWith('Patient-')) };
  }

  return { ...videoGalleryProps, remoteParticipants: videoGalleryProps.remoteParticipants.filter(x => x.displayName != 'Web Patient') };
}

function filterRemoteParticipantsOther(videoGalleryProps, isGroup) {

  if (isGroup) {
    videoGalleryProps.remoteParticipants.filter(x => {
      if (x.displayName?.startsWith('Patient-')) {
        x.displayName = 'Web Patient';
      }
    });
  }

  return videoGalleryProps;
}

export default CallingComponents;