import { localStorageService } from '../service/localStorageService';

const renderImageAttachments =  function(attachment) {
  return '<a id="link_' + attachment.id + '" target="_blank">' + 
    '<img alt="image" src="" itemscope="png" id="' + attachment.id + '" style="max-width: 100px">' + 
    '</a>';
};

const renderFileAttachments =  function(attachment) {
  var re = /(?:\.([^.]+))?$/;
  var fileExtension = re.exec(attachment.name)[1]; 
  return '<div class="attachment-container">' +
  '<div style="position: relative; height: auto;">' + 
  '<p class="attachment-type">' + fileExtension + '</p>' +
  '<img class="attachment-icon" alt="attachment file icon" />' +
  '</div>' +
  '<div>' +
  '<p>' + attachment.name + '</p>' +
  '<a href=' + attachment.previewUrl + ' target="_blank" rel="noreferrer">Open</a>' +
  '</div>' +
  '</div>';
};

const fetchPreviewImages =  function(theAttachments,token) {

    if (!theAttachments.length > 0) {
        return;
    }

    var attachments = handleAttachmentList(theAttachments)

    Promise.all(
        attachments.map(async (attachment) => {
            const response = await fetch(attachment.previewUrl, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            });
            return {
                id: attachment.id,
                content: await response.blob(),
            };
        }),
    ).then((result) => {
        result.forEach((imageRef) => {   
            
            const urlCreator = window.URL || window.webkitURL;
            const url = urlCreator.createObjectURL(imageRef.content);

            var elmlink = document.getElementById('link_'+ imageRef.id);
            if(elmlink != null){
              elmlink.href = url;
            }
            
            var elm = document.getElementById(imageRef.id);  
            if(elm != null){
              elm.src = url;
              elm.style='';
              elm.className="img-preview"; 
            }
        });
    }).catch((e) => {
        console.log('error fetching preview images');
    });
};

const setRenderedImageUrls =  function(token) {
  var imageAttachments = localStorageService.get("imageAttachments");
  
  if(imageAttachments !== null){
      fetchPreviewImages(JSON.parse(imageAttachments),token);
  }
};

const handleAttachmentList =  function(attachments) {
   
    var localStorageAttachments = localStorageService.get("imageAttachments");

    if(localStorageAttachments === null)
    {
      localStorageService.set('imageAttachments', JSON.stringify(attachments),getDateForExpiry());
      return attachments;
    }
    else
    {
      var attachmentList = JSON.parse(localStorageAttachments);

      for (var i = 0; i < attachments.length; i++) 
      {
          if(!existsInList(attachmentList,attachments[i]))
          {
              attachmentList.push(attachments[i]);
          }
      }
   
      localStorageService.set('imageAttachments', JSON.stringify(attachmentList),getDateForExpiry());

      return attachmentList;
    }
};

const existsInList =  function(attachments,attachment) {
  
  for (var i = 0; i < attachments.length; i++) 
  {
    if(attachments[i].previewUrl === attachment.previewUrl){
      return true;
    }
  }
  return false;
};

const getDateForExpiry = function()
{
  var date = new Date();
  date.setTime(date.getTime() + (5 * 60 * 1000));
  return date;
};

export { renderImageAttachments,renderFileAttachments,fetchPreviewImages,setRenderedImageUrls };