const localStorageService = {

  set: (key, value, date = null) => {
      var item = {
          value: value
        }
        
        if(date != null)
        {
          item = {
            value: value,
            expiry: date,
          }
        }
      
        localStorage.setItem(key, JSON.stringify(item));
  },
  get: (key) => {
      var itemStr = localStorage.getItem(key)

      if (!itemStr) {
          return null
      }
      var item = JSON.parse(itemStr)

      if (item.expiry == null) {
          return item.value;
      }

      var now = new Date();
      if (now.getTime() > item.expiry) {
          localStorage.removeItem(key)
          return null
      }

      return item.value
  }
}
export { localStorageService }