import React, { useEffect, useState } from 'react';
import { Features } from "@azure/communication-calling";
import {
  Icon,
  MessageBar,
  MessageBarType,
    MessageBarButton,
  Link
} from '@fluentui/react';

const RecordingMessage = (props) => {

    const [isRecording, setIsRecording] = useState(false);
    
    useEffect(() => {

        const isRecordingActiveChangedHandler = () => {
            console.log("Recording state: " + props.call.feature(Features.Recording).isRecordingActive);
            setIsRecording(props.call.feature(Features.Recording).isRecordingActive);
        };
        isRecordingActiveChangedHandler();
        
        props.call.feature(Features.Recording).on('isRecordingActiveChanged', isRecordingActiveChangedHandler);  
        
        return () => {
            props.call.feature(Features.Recording).off('isRecordingActiveChanged', isRecordingActiveChangedHandler);  
          };
    }, []);

    

    return (
    <>
        {(isRecording) ? (
            <MessageBar messageBarType={MessageBarType.info}>
               {props.portalSettings.inConsultationRecordingMessage}
                <Link href={props.portalSettings.inConsultationRecordingLink} target="_blank">
                    Details
                </Link>
            </MessageBar>            
        ) : <></>}
        </>
    );
};

export default RecordingMessage;