import React from 'react';

const TextOnlyRoom = (props) => (

   <div className="col call-setup">
                        <div className="inner">
                            <div className="inner__body">
                                <span><i className="icon-phone-call"></i></span>
                                <h2>This is a text &amp; audio only room. You will not be seen.</h2>
                   
                        <p>You may still hear your clinician, but text-based chat will be primarily how you will communicate.</p>
                       
                    </div>
                     
                            <div className="inner__cta">
                                <a href="https://docs.oneconsultation.net/patient-guidance.html" target="_blank" rel="noreferrer" className="begin-test"><span className="btn-inner">Tips &amp; Guidance<span className="tooltip"><span className="tooltip__btn"><i className="icon-info"></i></span><span className="tooltip__content"><p>First time here? Click for advice on getting set up for video consultation.</p></span></span></span></a>
                            </div>
                        </div>
                    </div>
    

);

export default TextOnlyRoom