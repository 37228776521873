import React, { useEffect, useState } from 'react';
import PortalContainer from '../PortalContainer';
import PortalSettingsCheck from './PortalSettingsCheck';
import PreConnectionContainer from './PreConnectionContainer';
import WebRTCEntryProblem from './WebRTCEntryProblem';


const WebRTCEntryCheckAudioOnly = (props) => {

    const [errorMessage, setErrorMessage] = useState("");
    const [checksComplete, setChecksComplete] = useState(false);
        const [audioDevices, setAudioDevices] = useState([]);
    const consultationRequest = {};

    useEffect(() => {
    
        let DetectRTC = require('detectrtc');
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(() => {
                DetectRTC.load(function () {
                    try {
            
                        if (!DetectRTC.hasMicrophone) {
                            setErrorMessage(" It looks like you don't have a microphone attached. (Error code 2)");
                            // insights.trackEvent('ClientError2');
                        }

                        if (!DetectRTC.isWebRTCSupported) {
                            setErrorMessage(errorMessage + " Your browser does not support WebRTC. Please use a modern browser with WebRTC support. (Error code 3)");
                            //insights.trackEvent('ClientError3');
                        }

                        // if (DetectRTC.browser.name === 'Safari' && DetectRTC.osName === "iOS") {
                        //     props.isIOS = true;
                        // }

                      

                
                    
                        console.log("Web RTC Check OK");

                      

                        setAudioDevices(DetectRTC.audioInputDevices.map(device => {
                            let deviceList = {};
                            deviceList["text"] = device.label;
                            deviceList["key"] = device.deviceId;
                            return deviceList;
                        }));

                        setChecksComplete(true);
                        consultationRequest.IsMobile = DetectRTC.isMobileDevice;
                        consultationRequest.Browser = DetectRTC.browser.name;
                        consultationRequest.OS = DetectRTC.osName;
           
                    }
                    catch (ex) { //internet explorer handling
                        console.log(ex);
                        setErrorMessage("Your browser does not support WebRTC. Please use a modern browser with WebRTC support. (Error code 1xx)");
                        setChecksComplete(true);
                        // insights.trackEvent('ClientError1xx');
                    }
                });
            }
                    ).catch(() => {
                            console.log("getUserMedia problem");
                            setErrorMessage("Either you don't have a webcam or you haven't given us permission to access your webcam and microphone. We need these for your consultation to work. Please reload the page and allow access when prompted. (Error code 4)");
                            setChecksComplete(true);
                        }
                        );
        
    }, []);

  

    return (
        <>
            {/* <pre>WebRTC Checks Complete? {checksComplete.toString()} | WebRTC Errors: {errorMessage}</pre>             */}
            {checksComplete ? (
                errorMessage.length > 0 ? <WebRTCEntryProblem errorMessage={errorMessage}></WebRTCEntryProblem> : <PortalContainer portalSettings={props.portalSettings} isJoinLink={props.isJoinLink} isPrepopulated={props.isPrepopulated} consultationRequest={consultationRequest} audioDevices={ audioDevices}></PortalContainer>
            ) : (
                    <h2 className="service-message">Checking your devices and WebRTC settings. If you are asked whether or not to allow use of your microphone and camera, click <strong>Allow</strong>. This is so that we can use your camera and microphone for your consultation.</h2>
                )
            }                
            
            </>
  )         

};

export default WebRTCEntryCheckAudioOnly