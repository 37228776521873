import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SERVICE_BASE, ONEC_API_OPTIONS } from '../../globals';
import WebRTCEntryCheck from './WebRTCEntryCheck';
import WebRTCEntryCheckAudioOnly from './WebRTCEntryCheck-AudioOnly';
import SiteOffline from './SiteOffline';
const PortalSettingsCheck = (props) => {

      const [isSiteUnkown, setIsSiteUnknown] = useState(false);
      const [isSiteOffline, setIsSiteOffline] = useState(false);
      const [portalGetDone, setPortalGetDone] = useState(false);
      const [portalSettings, setPortalSettings] = useState({});
    

     useEffect(() => {    
    async function get() {
      await getPortalSettings();
    };
    get();
     }, []);
    
    
    const getPortalSettings = async () => {
    try {
      const response = await axios.get(`${SERVICE_BASE}/settings/portal`, ONEC_API_OPTIONS);
      console.log(`Portal Settings: ${response.statusText}`);
      let portalSettings = response.data;
      setPortalSettings(portalSettings);
      setIsSiteOffline(!portalSettings.isOnline);
     
      
      
    }
    catch (ex) {
      if (ex.isAxiosError && ex.response.status === 404) {
        setIsSiteUnknown(true);
        
      }
      else {
        console.log(ex);
        setIsSiteOffline(true);
        
      }
      
    }
    setPortalGetDone(true);
  };

    return (
        <>        
        
          {(portalGetDone && isSiteUnkown) ? (
        <div>Site Unknown</div>) : <></>
           }

      {(portalGetDone && isSiteOffline) ?
      <SiteOffline portalSettings={portalSettings} /> : <></>
        }
    
            { (portalGetDone && !isSiteUnkown && !isSiteOffline && !portalSettings.isTextOnly) ? (
                <>
               
                    <WebRTCEntryCheck isJoinLink={props.isJoinLink} isPrepopulated={props.isPrepopulated} portalSettings={portalSettings}></WebRTCEntryCheck>
                    </>
              )
        : <></>
            }

            { (portalGetDone && !isSiteUnkown && !isSiteOffline && portalSettings.isTextOnly) ? (
                <>
                   
                    <WebRTCEntryCheckAudioOnly isJoinLink={props.isJoinLink} isPrepopulated={props.isPrepopulated} portalSettings={portalSettings}></WebRTCEntryCheckAudioOnly>
                    </>
              )
        : <></>
            }
            
</>
);
};


export default PortalSettingsCheck;