import React from 'react';
import { FontIcon, Icon } from '@fluentui/react/lib/Icon';

const GroupSessionAccessCode = (props) => {

    const LockIcon = () => <Icon iconName="Lock" />;
    
    
    return (
        <div className="input">
            <span className="field-icon">
                <span><LockIcon aria-label="LockIcon" iconName="LockIcon" /></span>
            </span>
            <input placeholder="Access Code" type="password" autoComplete="off" onChange={event => props.onAccessCodeEntry(event.target.value)} required />
          
        
        </div>
    );

};

export default GroupSessionAccessCode;