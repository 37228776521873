import React from 'react';
import { createRoot } from 'react-dom/client';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
 import './style.css';
 import './index.css';
 import { registerIcons } from '@fluentui/react';
 import { DEFAULT_COMPONENT_ICONS} from '@azure/communication-react';
import{initializeIcons } from '@fluentui/react/lib/Icons';
import { BoardSplit20Regular,Grid20Regular, PersonSquare20Regular, ContentView20Regular, TableSimple20Regular, WindowHeaderHorizontal20Regular, Table20Regular} from '@fluentui/react-icons';

const extraIcons = {
  FloatingLocalVideoGalleryLayout: <BoardSplit20Regular />,
  Galleryoptions : <Grid20Regular />,
  Speakergallerylayout  : <PersonSquare20Regular />,
  FocusedContentGalleryLayout : <ContentView20Regular />,
  DefaultGalleryLayout : <TableSimple20Regular />,
  OverflowGalleryTop : <WindowHeaderHorizontal20Regular />,
  LargeGalleryLayout: <Table20Regular />,
};

initializeIcons();
registerIcons({ icons: {...DEFAULT_COMPONENT_ICONS, ...extraIcons }});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
