import React, { forwardRef, useImperativeHandle,useRef } from 'react';

const ChatTextArea = forwardRef((props,ref) => {
        useImperativeHandle(ref, () => ({
          clickSendMessage() {
            sendMessage();
          }
        }));
        
  const textAreaRef = useRef(null);

  const handleChange = (e) => {
    props.setMessage(e.target.value);
    // Adjust the height of the textarea
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if(props.message)
    {
        props.sendMessage();
        props.setMessage('');
        resetHeight();
    }
  };

  const resetHeight=()=>{
  // Reset the height of the textarea
  textAreaRef.current.style.height = 'auto';
  }

  return (
    <textarea
      className="ChatSendTextInput" 
      placeholder="Type a new message"
      ref={textAreaRef}
      value={props.message}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      rows={1}
      style={{ overflow: 'hidden', resize: 'none' }}
    />
  );
});

export default ChatTextArea;