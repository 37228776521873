import {
  CameraButton,
  ControlBar,
  EndCallButton,
  MicrophoneButton,
  RaiseHandButton,
  FluentThemeProvider,
  usePropsFor  
} from '@azure/communication-react';
import { MoreButton } from "./MoreButton";
import { DesktopMoreButton } from "./DesktopMoreButton";

import React, { useState } from 'react';

import ChatButton from './ChatButton';
import DisconnectModal from './DisconnectModal';

const ControlbarComponent = (props) => {

  const cameraProps = usePropsFor(CameraButton);
  const microphoneProps = usePropsFor(MicrophoneButton);
  const endCallProps = usePropsFor(EndCallButton);
  const raiseHandProps = usePropsFor(RaiseHandButton);
  const [showDisconnectModal, setshowDisconnectModal] = useState(false);
  const onShowDisconnectModal = () => {
    setshowDisconnectModal(true);
  }

  const onHideDisconnectModal = () => {
    setshowDisconnectModal(false);
  }
  const componentMainDivStyle = {

    display: 'flex',
    height: '5rem',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <>
      <div style={componentMainDivStyle} className="fixed-bottom">
        <FluentThemeProvider>
          <ControlBar layout='floatingBottom'>
            {cameraProps && <CameraButton  {...cameraProps} />}
            {microphoneProps && <MicrophoneButton   {...microphoneProps} />}
            {endCallProps && <EndCallButton {...endCallProps} onHangUp={onShowDisconnectModal} />}
            {!props.hideChat && <ChatButton onClick={props.onChatClicked} showNewMessage={props.showNewMessage} chatMessagesCount={props.chatMessagesCount} />}
            {raiseHandProps && <RaiseHandButton  {...raiseHandProps} />}
            {!props.isAuditorium && 
              <DesktopMoreButton
                {...props}
                onUserSetOverflowGalleryPositionChange = {props.onUserSetOverflowGalleryPositionChange}
                onUserSetGalleryLayout={props.handleLayout}
                userSetGalleryLayout={props.userSetGalleryLayout}
              />}

          </ControlBar>
        </FluentThemeProvider>
      </div>
      {showDisconnectModal && <DisconnectModal onHangUp={props.onHangUp} showDisconnectModal={showDisconnectModal} hideDisconnectModal={onHideDisconnectModal} copyChatClick={props.copyChatClick} />}
    </>
  );
};
export default ControlbarComponent;

