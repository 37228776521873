


export function convertDiagnosticToMessageText(diagnostic)
{
    switch (diagnostic) {
        case "noNetwork":
            return "There is no network available.";
            break;
        case "microphoneNotFunctioning":
            return "Microphone is not functioning.";
            break;
        default:
            return diagnostic;
    }
	
	}


