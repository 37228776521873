import React, { useState, useEffect } from 'react';
import Question from './Question';
import ConfirmableMessage from './ConfirmableMessage';
import GroupSessionAccessCode from './GroupSessionAccessCode';
import GroupSessionDisplayName from './GroupSessionDisplayName';
import { useParams } from 'react-router-dom';
import { SERVICE_BASE, ONEC_API_OPTIONS } from '../../globals';
import axios from 'axios';

const QuestionList = (props) => {
    const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
    const [isQuestionsValid, setIsQuestionsValid] = useState(false);
    const [isConfirmationValue, setIsConfirmationValid] = useState(!props.showConfirmableMessage);
    const [groupSessionAccessCode, setGroupSessionAccessCode] = useState("");
    const [groupSessionDisplayName, setGroupSessionDisplayName] = useState("");
    const [isAccessCodeValid, setIsAccessCodeValid] = useState(false);
    const [isGroupSessionDisplayNameValid, setIsGroupSessionDisplayNameValid] = useState(false);
    const [isQuestionsSubmitted, setIsQuestionsSubmitted] = useState(false);
    const [className, setClassName] = useState("btn btn--lg btn--grey submit-btn");
    const [submitButtonText, setSubmitButtonText] = useState(props.beginConsultationText);
    const { prepopid } = useParams();

    useEffect(() => {
        if (props.isGroupRoom && props.questions.length == 0) {
            setIsQuestionsValid(true);
        } else {        
            if (props.isPrepopulated) {
                async function get() {
                    return await getPrepopulatedQuestions(prepopid)
                }
                get().then(function (data) {
                    if (data !== undefined) {
                        const qwa = [];
                        props.questions.sort((a, b) => a.questionOrder > b.questionOrder ? 1 : -1).forEach(function (origQuestion) {
                            let prepopQuestion = data.prepopulatedQuestions.find((pq) => pq.questionId === origQuestion.questionOrder);
                            if (prepopQuestion !== undefined) {
                                let qtopush = {rowKey: origQuestion.rowKey, questionId: origQuestion.questionOrder, questiontext: origQuestion.questiontext, questionanswer: prepopQuestion.answer };
                                qwa.push(qtopush);
                            } else {
                                let qtopush = {rowKey: origQuestion.rowKey, questionId: origQuestion.questionOrder, questiontext: origQuestion.questiontext, questionanswer: "" };
                                qwa.push(qtopush);
                            }
                        });
                        setQuestionsWithAnswers(qwa);
                        validQuestionsCheck(qwa);
                    } else {
                        initQuestionsWithAnswers();
                    }
                });
            } else {
                initQuestionsWithAnswers(); 
            };
        }
    }, []);

    const initQuestionsWithAnswers = () => {
        const qwa = [];
        props.questions.sort((a, b) => a.questionOrder > b.questionOrder ? 1 : -1).forEach(function (q) {  
            let qtopush = {rowKey: q.rowKey, questionId: q.questionOrder, questiontext: q.questiontext, questionanswer: "" };
            qwa.push(qtopush);
        });
        setQuestionsWithAnswers(qwa);  
    }

    const getPrepopulatedQuestions = async (data) => {
        try {
            const response = await axios.get(`${SERVICE_BASE}/consultations/GetPrepopulatedConsultation/${data}`, ONEC_API_OPTIONS);
            return response.data;
        }
        catch (ex) {
            console.log(ex)         
        }
    }
    
    const handleSubmit = e => {
        e.preventDefault();
        setSubmitButtonText("Connecting...");
        setIsQuestionsSubmitted(true);
        props.onAnswersSubmitted(questionsWithAnswers, groupSessionAccessCode, groupSessionDisplayName);
    };
    
    const handleQuestionChange = (answer, index) => {
        const qwa = [...questionsWithAnswers];
        qwa[index] = {...qwa[index], questionanswer: answer };
        setQuestionsWithAnswers(qwa);
        validQuestionsCheck(qwa);
    };

    const validQuestionsCheck = (qwa) => {
        if (props.questions.length === qwa.length && qwa.every((q) => q.questionanswer.trim().length > 0)) {
            setIsQuestionsValid(true);
            updateClassName(true, isConfirmationValue, isAccessCodeValid, isGroupSessionDisplayNameValid);
        } else {
            setIsQuestionsValid(false);
            updateClassName(false, isConfirmationValue, isAccessCodeValid, isGroupSessionDisplayNameValid);
        }
    }

    const handleConfirmableMessageChange = (e) => {
        setIsConfirmationValid(e);
        updateClassName(isQuestionsValid, e, isAccessCodeValid, isGroupSessionDisplayNameValid);
    };

    const handleAccessCodeEntry = (e) => {
        setGroupSessionAccessCode(e);
            setIsAccessCodeValid(e.length > 0);
            updateClassName(isQuestionsValid, isConfirmationValue, e.length > 0, isGroupSessionDisplayNameValid);       
    };

    const handleDisplayNameEntry = (e) => {
        setGroupSessionDisplayName(e);
        setIsGroupSessionDisplayNameValid(groupRoomDisplayNameValid(e));
        updateClassName(isQuestionsValid, isConfirmationValue, isAccessCodeValid, groupRoomDisplayNameValid(e))
    }

    const groupRoomDisplayNameValid = (name) => {
        return name.length >= 3;
    }

    const updateClassName = (questionValidState, confirmState, accessCodeState, groupDisplayNameState) => {
        if (questionValidState && confirmState && (!props.isGroupRoom || accessCodeState) && (!props.isGroupRoom || groupDisplayNameState)) {
            setClassName("btn btn--lg btn--green submit-btn");
        }
        else {
            setClassName("btn btn--lg btn--grey submit-btn");
        }
    };
    
    return (
        <>
            <form id="beginConsultation" onSubmit={handleSubmit}>
                <fieldset>
                    {questionsWithAnswers.map((question, i) => <Question key={question.rowKey} index={i} onQuestionChange={handleQuestionChange} {...question} />)}
                    {props.isGroupRoom ? (
                        <>
                            <GroupSessionDisplayName onDisplayNameEntry={handleDisplayNameEntry} />
                            <GroupSessionAccessCode onAccessCodeEntry={handleAccessCodeEntry} />
                        </>
                    ): (<></>)}
                    <ConfirmableMessage showConfirmableMessage={props.showConfirmableMessage} confirmationMessageText={props.confirmationMessageText} onConfirmableMessageChange={handleConfirmableMessageChange}></ConfirmableMessage>
                    <p className="hint"><sup>*</sup>Required fields</p>    
                </fieldset>
            <button className={className} type="submit" id="submit" disabled={!isQuestionsValid || !isConfirmationValue || isQuestionsSubmitted || (props.isGroupRoom && !isAccessCodeValid) || (props.isGroupRoom && !isGroupSessionDisplayNameValid)} onSubmit={handleSubmit}>{submitButtonText}</button>
            </form>
        </>
    )
};

export default QuestionList;