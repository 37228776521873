import React, { useEffect, useState } from 'react';
import { LocalVideoStream } from "@azure/communication-calling";
import { createStatefulCallClient } from '@azure/communication-react';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import ConnectingCall from './ConnectingCall';
import InLobbyCall from './InLobbyCall';
import DisconnectedCall from './DisconnectedCall';
import { displayLocalVideo } from '../utils/renderLocalVideo';
import ErrorConnectingCall from './ErrorConnectingCall';
import TextOnlyConnectedCall from './TextOnlyConnectedCall';
import ConnectedCallUIComponent from './ConnectedCallUIComponent';
import { setReJoinSessionData } from '../service/reconnectCallService';
import { getReJoinSessionPatientDisplayName } from '../service/reconnectCallService';

const ConnectionContainerUIComponent = (props) => {

    const [statefulCallClient, setStatefulCallClient] = useState();
    const [callAgent, setCallAgent] = useState();
    const [callState, setCallState] = useState("Unknown");
    const [callProp, setCallProp] = useState();
    const [localVideoStreamProp, setLocalVideoStreamProp] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [identity, setIdentity] = useState("");

    useEffect(() => {

        setReJoinSessionData(props);
        setStatefulCallClient(createStatefulCallClient({
            userId: { communicationUserId: props.consultationResponse.acsUserId }
        }));
    }, [props.consultationResponse.acsUserId]);

    useEffect(() => {
        const tokenCredential = new AzureCommunicationTokenCredential(props.token);

        setIdentity('Web Patient');

        var sessionpatientDisplayName = getReJoinSessionPatientDisplayName();

        if (isGroup() && props.portalSettings.isShowPatientName) {
            setIdentity(sessionpatientDisplayName);
        } 
        else if (isAuditoriumRoom() || isGroup()) {
            let patientPrefix = 'Patient-';

            if (sessionpatientDisplayName != null) {
                setIdentity(patientPrefix + sessionpatientDisplayName);
            }
            else {
                setIdentity(props.patientDisplayName !== null && props.patientDisplayName !== "" ? patientPrefix + props.patientDisplayName : identity);
            }
        }

        if (callAgent === undefined && statefulCallClient) {
            const createUserAgent = async () => {

                setCallAgent(await statefulCallClient.createCallAgent(tokenCredential, { displayName: identity }));
            }

            createUserAgent();
        }
    }, [statefulCallClient, callAgent]);

    useEffect(() => {
        if (callAgent !== undefined) {
            console.log("Connection Container useEffect");
            navigator.mediaDevices.getUserMedia({ video: props.textOnlyRoom ? false : true, audio: true }).
                then(() => {

                    try {

                        if (props.textOnlyRoom) {
                            const createSetupTextCall = async () => {

                                await setupTextCall();
                            }

                            createSetupTextCall();
                        }
                        else {
                            const createSetupVideoCall = async () => {

                                await setupVideoCall();
                            }

                            createSetupVideoCall();
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                        setErrorMessage(ex.name + ": " + ex.message);
                        setShowError(true);
                    }

                }).catch(getWebRTCAccessBlocked);

        }
        return () => {
            if (callProp) {
                callProp.off('stateChanged', () => { onStateChanged(callProp); });
            }
        }

    }, [callAgent]);

    function isAuditoriumRoom() {
        return props.portalSettings.isAuditorium === true && props.portalSettings.isGroup === true;
    }

    function isGroup() {
        return props.portalSettings.isGroup === true;
    }

    function getWebRTCAccessBlocked(error) {
        setErrorMessage(error.name + ": " + error.message);
        setShowError(true);
    }

    const setupVideoCall = async () => {
        const deviceManager = await statefulCallClient.getDeviceManager();

        let allDevices = await deviceManager.getCameras();
        const videoDeviceInfo = allDevices.find(x => x.id.toString().includes(props.videoKey));
        console.log("selected video device:" + videoDeviceInfo);

        const localVideoStream = new LocalVideoStream(videoDeviceInfo);

        const callJoinOptions = { videoOptions: { localVideoStreams: [localVideoStream] }, audioOptions: { muted: false } };
        const call = callAgent.join({ meetingLink: props.teamsMeeting }, callJoinOptions);

        call.on('stateChanged', () => { onStateChanged(call); });
        setCallProp(call);
        setCallState(call.state);
        setLocalVideoStreamProp(localVideoStream);
        displayLocalVideo(localVideoStream);
    }

    const setupTextCall = async () => {

        const callJoinOptions = { audioOptions: { muted: true } };
        const call = callAgent.join({ meetingLink: props.teamsMeeting }, callJoinOptions);

        call.on('stateChanged', () => { onStateChanged(call); });
        setCallProp(call);
        setCallState(call.state);

    };

    const onStateChanged = (call) => {
        console.log("Call state:" + call.state);
        setCallState(call.state);
    };


    return (
        <>
            <header className="no-nav">
                <div className="container">
                    <div className="logo">
                        <img src={props.portalLogoUrl} alt={props.portalTitle} className="logo"></img>
                    </div>
                </div>
            </header>

            {(showError) ? (
                <ErrorConnectingCall errorMessage={errorMessage}></ErrorConnectingCall>
            ) :

                <main className="wb call-screen">
                    <section>
                        <div className="container-fluid">
                            <div className="screen-wrapper">

                                {(callState === "InLobby") ? (
                                    <InLobbyCall callState={callState}
                                        disconnectUrl={props.disconnectUrl}
                                        call={callProp}
                                        waitingRoomLayout={props.waitingRoomLayout}
                                        consultationId={props.consultationId}
                                        operatingSystem={props.consultationRequest.OS}
                                        browser={props.consultationRequest.Browser}
                                        userIPAddress={props.portalSettings.userIPAddress}
                                        isGroupCall={props.isGroupCall} ></InLobbyCall>
                                ) : <></>}


                                {(callState === "Connected" && !props.textOnlyRoom) ? (
                                    <ConnectedCallUIComponent
                                        statefulCallClient={statefulCallClient} callAgent={callAgent}
                                        acsEndpoint={props.consultationResponse.chatEndpointUrl}
                                        callState={callState} chatThreadId={props.chatThreadId} call={callProp}
                                        token={props.token} localVideoStream={localVideoStreamProp}
                                        consultationId={props.consultationId} isGroupCall={props.isGroupCall}
                                        portalSettings={props.portalSettings} displayName={identity}></ConnectedCallUIComponent>
                                ) : <></>}

                                {(callState === "Connected" && props.textOnlyRoom) ? (
                                    <TextOnlyConnectedCall statefulCallClient={statefulCallClient} callAgent={callAgent} chatThreadId={props.chatThreadId} call={callProp} token={props.token} localVideoStream={localVideoStreamProp} consultationId={props.consultationId} isGroupCall={props.isGroupCall} portalSettings={props.portalSettings}></TextOnlyConnectedCall>
                                ) : <></>}

                                {(!props.textOnlyRoom) ? (
                                    <div className="user-screen"><div className="local-render" id="local-render"></div></div>
                                ) : <></>}

                                {(callState === "Unknown" || callState === "Connecting") ? (
                                    <ConnectingCall></ConnectingCall>
                                ) : <></>}

                                {(callState === "Disconnected") ? (
                                    <DisconnectedCall disconnectUrl={props.disconnectUrl} consultationId={props.consultationId} isGroupCall={props.isGroupCall}></DisconnectedCall>
                                ) : <></>}
                            </div>
                        </div>
                    </section>
                    <div className="bg">
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                        <span className="bubble"></span>
                    </div>
                </main>
            }


        </>
    );
};

export default ConnectionContainerUIComponent;