import React from 'react';
import { Dropdown } from '@fluentui/react';
import { FontIcon, Icon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const Question = (props) => {

    const QuestionIcon = () => <Icon iconName="StatusCircleQuestionMark" />;
    


    let displayText = props.questiontext;
    if (props.ismandatory) {
        displayText += "*";
    }

    return (
        <div className="input">
            <span className="field-icon field-icon-small">
                <span><QuestionIcon aria-label="StatusCircleQuestionMark" iconName="StatusCircleQuestionMark"  /></span>
            </span>
            <input placeholder={displayText} value={props.questionanswer} type="text" autoComplete="off" onChange={event => props.onQuestionChange(event.target.value, props.index)} id={props.rowKey} required />
            {/* <label htmlFor={props.rowKey}>{props.questiontext} */}
        

            {/* </label> */}
        
        </div>
    );
};
 
 
        
    



export default Question;