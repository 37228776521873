import {ControlBarButton,useTheme} from '@azure/communication-react';
import {concatStyleSets,Stack } from '@fluentui/react';
import React, {useMemo } from 'react';
import {Chat20Filled} from '@fluentui/react-icons';
import NotificationIcon from './NotificationIcon';


const ChatButton = (props) => {
    const strings = { label: props.label, ...props.strings };
    const theme = useTheme();
    const styles = useMemo(
      () =>
        concatStyleSets(
          {
            rootChecked: {
              background: theme.palette.neutralLight
            }
          },
          props.styles===undefined? {}:props.styles
        ),
      [props.styles, theme.palette.neutralLight]
    );
    return (
      <ControlBarButton
        {...props}
        labelKey={'chatButtonLabelKey'}
        strings={strings}
        onClick={props.onClick}
         onRenderIcon={() => <ChatIcon chatMessagesCount={props.chatMessagesCount} showNewMessage={props.showNewMessage} />}
        styles={styles}
      />
    );
  };

  export default ChatButton;

  const ChatIcon=(props)=>{

    const baseIcon = <Chat20Filled />;

      return (
        <Stack styles={chatNotificationContainerStyles}>
          {props.chatMessagesCount > 0 && (
            <NotificationIcon chatMessagesCount={props.chatMessagesCount} label={props.newMessageLabel} />
          )}
          {baseIcon}
        </Stack>
      );
};
  
  const chatNotificationContainerStyles = {
    root: {
      display: 'inline',
      position: 'relative'
    }
  };