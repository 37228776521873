import React, { useEffect } from 'react';
import { PrimaryButton } from '@fluentui/react';
import bootstrap from 'bootstrap';
import { ReportCallInLobby, ReportCallInProgress } from '../utils/reportCallStatus';
import { IIconProps } from '@fluentui/react';
import { clearReJoinSessionData,setReJoinSessionDataExpiry  } from '../service/reconnectCallService';

const InLobbyCall = (props) => {

const endCallIcon: IIconProps = { iconName: 'DeclineCall' };

    useEffect(() => {    
        async function reportStatus() {
            console.log("Browser: " + props.browser + ", OS: " + props.operatingSystem+ ", IPAddress: " + props.userIPAddress);
            await ReportCallInLobby(props.consultationId, props.operatingSystem, props.browser,props.userIPAddress,props.isGroupCall);           
    };        
        reportStatus();

         const timer = setInterval(() => {
            checkCallStillValidAndUpdate();
             }, 30000);

         //cleanup
         return () => {
            console.log("Leaving InLobby reporting");
            clearInterval(timer);
        };
     }, []);

    const onDisconnect = () => {
        if (window.confirm("You are in our waiting room. Your consultation will start soon. Are you sure you wish to leave?")) {
            clearReJoinSessionData();
            props.call.hangUp();
               
        }
    };

      const checkCallStillValidAndUpdate = async () => {
        var validCheck = await ReportCallInProgress(props.consultationId, props.isGroupCall);
        if (!validCheck)
        {
            clearReJoinSessionData();
            //call no longer valid, disconnect
            props.call.hangUp();
            window.alert("Your call has been disconnected.");
        }
        else{
            setReJoinSessionDataExpiry();
        }
    };


    return (
        <>
            <div className="visitor-screen">

                {props.waitingRoomLayout !== null ? (
                <div id="waitingRoomContent" dangerouslySetInnerHTML={{__html: props.waitingRoomLayout}}></div>
                ) :  
                    (
                
                        <div id="waitingRoomContent" style={{ height: "100%" }}>
                            <div style={{ background: "black", borderRadius: "0.25rem", height: "100%", display: "grid"}}>
                              
                                <span style={{color:"white",left:"50%",maxWidth:"30rem",position:"absolute",textAlign:"center",top:"20%",webkitTransform: "translate(-50%, calc(-50% - 2rem))", transform: "translate(-50%, calc(-50% - 2rem))", zIndex: 3,fontSize: "2.25em",lineHeight: "2.8125rem"  }}>You are in our waiting room. Please wait here and do not navigate away. Your session will start soon.<br />
                                
                            </span><br />
                            </div>
                        </div>

                       
                        

                )}


                <div className="waitingRoomButtons">
                    <PrimaryButton iconProps={endCallIcon} text="Disconnect" onClick={onDisconnect} allowDisabledFocus />
                    </div>
            </div>
            
        </>
    );
}

export default InLobbyCall;