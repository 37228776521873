
const ChatMessage = (props) => {

    const messageDate = new Date(props.message.createdOn);
    const isFromSelf = (props.message.sender.communicationUserId === props.message.recipient.communicationUserId ? true : false);
    let isWebPatient = (props.message.sender.kind === 'communicationUser') ? true : false;

    let fromString = "";
    if (props.isShowPatientName) {
        fromString = isFromSelf ? "You" : props.displayName;
    } else {
        fromString = isFromSelf ? "You" : isWebPatient ? 'Web Patient' : "Your Consultant";
    }


    return (
        <>
            <div className={isFromSelf ? "ChatMessage SelfMessage" : "ChatMessage"}>
                <div className="ChatHeader">
                    <span className="ChatMessageFrom">{fromString}</span>
                    <span className="ChatMessageTime">{messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                </div>
                <span className="ChatMessageContent" dangerouslySetInnerHTML={{ __html: props.message.message }}></span>
            </div>
        </>
    );
}

export default ChatMessage;