import React, { useEffect, useState } from 'react';
import { Features } from "@azure/communication-calling";
import QoSInformationMessage  from "./QoSInformationMessage";
import { convertDiagnosticToMessageText } from '../utils/diagnosticMessages';

const QoSInformationMessages = (props) => {

    const [activeMessages, setActiveMessages] = useState([]);
    
    
    useEffect(() => {


        const messageHandler = (diagnosticInfo) => {
            console.log("Message: " + diagnosticInfo.diagnostic)
            if (diagnosticInfo.valueType == 'DiagnosticQuality')
            {
                ProcessDiagnosticQualityMessages(diagnosticInfo);                
            }
            else if (diagnosticInfo.valueType == 'DiagnosticFlag')
            {
                ProcessDiagnosticFlagMessages(diagnosticInfo);
            }
            else
            {
                console.log("Unknown Diagnostic Message Type: " + diagnosticInfo.valueType);
                console.log(diagnosticInfo);
                }
        };
                
        props.call.feature(Features.UserFacingDiagnostics).network.on('diagnosticChanged', messageHandler);
        props.call.feature(Features.UserFacingDiagnostics).media.on('diagnosticChanged', messageHandler);
        console.log("Subscribed to diagnostic messages");
        return () => {
            props.call.feature(Features.UserFacingDiagnostics).network.off('diagnosticChanged', messageHandler);
            props.call.feature(Features.UserFacingDiagnostics).media.off('diagnosticChanged', messageHandler);
          };

    }, []);

    const ProcessDiagnosticQualityMessages = (diagnosticInfo) => {
        if (diagnosticInfo.value == 2 || diagnosticInfo.value == 3) {
            AddMessage(diagnosticInfo);
        }
        else {
            RemoveMessage(diagnosticInfo);
        }
    };

    const ProcessDiagnosticFlagMessages = (diagnosticInfo) => {
        if (diagnosticInfo.value) {
            AddMessage(diagnosticInfo);
        }
        else {
            RemoveMessage(diagnosticInfo)
        }
    };

    const AddMessage = (diagnosticInfo) => {
        RemoveMessage(diagnosticInfo);

        diagnosticInfo.messageText = convertDiagnosticToMessageText(diagnosticInfo.diagnostic);
        diagnosticInfo.key = diagnosticInfo.diagnostic;
        setActiveMessages([...activeMessages, diagnosticInfo]);
    };

    const RemoveMessage = (diagnosticInfo) => {
        let newArray = activeMessages.filter(message => message.diagnostic != diagnosticInfo.diagnostic);
        setActiveMessages(newArray);
    };

    return (
    <>
       {activeMessages.map(message => <QoSInformationMessage key={message.key} MessageText={message.messageText} />)}
        </>
    );
};

export default QoSInformationMessages;