import React from 'react';
import { BrowserRouter as Router, Routes as ReactRoutes, Route } from 'react-router-dom';
import PortalSettingsCheck from './components/preconnection/PortalSettingsCheck';




const Routes = () => {
    return (
        <Router>
            <ReactRoutes>
                <Route exact path='/' element={<PortalSettingsCheck />} />
                <Route exact path="/prepopulated/:prepopid" element={<PortalSettingsCheck isPrepopulated={true} /> } />
                <Route exact path="/join/:callid" element={<PortalSettingsCheck isJoinLink={true} /> } />
            </ReactRoutes>
        </Router>
    )
};

export default Routes;