
const SiteOffline = (props) => {
    return (
            <div className="offline-background">
            <div className="wrapper">
               <img className='logo' src={props.portalSettings.portalLogoUrl} alt={props.portalSettings.portalTitle} />
               <img  src="https://cdn.modalitysystems.com/OneConsultation/logo/room-offline.jpeg" alt='Room is offline' />
            </div>
            </div>
    );

};

export default SiteOffline;