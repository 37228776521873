import { useMemo, useState } from "react";
import { generateCustomCallDesktopOverflowButtons, onFetchCustomButtonPropsTrampoline } from "./CustomButton";
import { MoreButton } from "./MoreButton";

  const MINIMUM_TOUCH_TARGET_HEIGHT_REM = 3;

export const buttonFlyoutIncreasedSizeStyles = {
  root: {
    height: `${MINIMUM_TOUCH_TARGET_HEIGHT_REM}rem`,
    lineHeight: `${MINIMUM_TOUCH_TARGET_HEIGHT_REM}rem`,
    maxHeight: 'unset',
    overflow: 'hidden'
  },
  linkContent: {
    height: `${MINIMUM_TOUCH_TARGET_HEIGHT_REM}rem`,
    lineHeight: `${MINIMUM_TOUCH_TARGET_HEIGHT_REM}rem`,
    maxHeight: 'unset'
  },
  icon: {
    maxHeight: 'unset',
    textAlign: 'center'
  },
  checkmarkIcon: {
    lineHeight: '2rem'
  }
};

export const CUSTOM_BUTTON_OPTIONS = {
  MAX_PRIMARY_DESKTOP_CUSTOM_BUTTONS: 3,
  MAX_PRIMARY_MOBILE_CUSTOM_BUTTONS: 1,
  MAX_SECONDARY_DESKTOP_CUSTOM_BUTTONS: 2
};

export const _preventDismissOnEvent = (ev) => {
  return ev.type === 'resize' || ev.type === 'scroll';
};

  
export const DesktopMoreButton = (props) => {

  const localeStrings = "";
  

  const [galleryPositionTop, setGalleryPositionTop] = useState(false);
  const [focusedContentOn, setFocusedContentOn] = useState(false);
  const [previousLayout, setPreviousLayout] = useState(
    props.userSetGalleryLayout ?? 'floatingLocalVideo'
  );

  const moreButtonStrings = useMemo(
    () => ({
      label: "More",
      tooltipOffContent: "More Options"
    }),
    [localeStrings]
  );

  const moreButtonContextualMenuItems = [];

  const menuSubIconStyleSet = {
    root: {
      height: 'unset',
      lineHeight: '100%',
      width: '1.25rem'
    }
  };

  if (props.onUserSetOverflowGalleryPositionChange) {
    const galleryOptions = {
      key: 'overflowGalleryPositionKey',
      iconProps: {
        iconName: 'GalleryOptions',
        styles: { root: { lineHeight: 0 } }
      },
      itemProps: {
        styles: buttonFlyoutIncreasedSizeStyles
      },
      submenuIconProps: {
        styles: menuSubIconStyleSet
      },
      text: "View",
      disabled: props.disableButtonsForHoldScreen,
      subMenuProps: {
        items: [
          {
            key: 'dynamicSelectionKey',
            text: "Dynamic",
            canCheck: true,
            itemProps: {
              styles: buttonFlyoutIncreasedSizeStyles
            },
            isChecked: props.userSetGalleryLayout === 'floatingLocalVideo',
            onClick: () => {
              props.onUserSetGalleryLayout && props.onUserSetGalleryLayout('floatingLocalVideo');
              setFocusedContentOn(false);
            },
            iconProps: {
              iconName: 'FloatingLocalVideoGalleryLayout',
              styles: { root: { lineHeight: 0 } }
            }
          },
          {
            key: 'speakerSelectionKey',
            text: "Speaker",
            canCheck: true,
            itemProps: {
              styles: buttonFlyoutIncreasedSizeStyles
            },
            isChecked: props.userSetGalleryLayout === 'speaker',
            onClick: () => {
              props.onUserSetGalleryLayout && props.onUserSetGalleryLayout('speaker');
              setFocusedContentOn(false);
            },
            iconProps: {
              iconName: 'SpeakerGalleryLayout',
              styles: { root: { lineHeight: 0 } }
            }
          },
          {
            key: 'focusedContentSelectionKey',
            text: "Focus on content",
            canCheck: true,
            itemProps: {
              styles: buttonFlyoutIncreasedSizeStyles
            },
            isChecked: focusedContentOn,
            onClick: () => {
              if (focusedContentOn === false) {
                setPreviousLayout(props.userSetGalleryLayout ?? 'floatingLocalVideo');
                props.onUserSetGalleryLayout && props.onUserSetGalleryLayout('focusedContent');
                setFocusedContentOn(true);
              } else {
                props.onUserSetGalleryLayout && props.onUserSetGalleryLayout(previousLayout);
                setFocusedContentOn(false);
              }
            },
            iconProps: {
              iconName: 'FocusedContentGalleryLayout',
              styles: { root: { lineHeight: 0 } }
            }
          }
        ],
        calloutProps: {
          preventDismissOnEvent: _preventDismissOnEvent
        }
      }
    };

    const galleryOption = {
      key: 'defaultSelectionKey',
      text: "Gallery view",
      canCheck: true,
      itemProps: {
        styles: buttonFlyoutIncreasedSizeStyles
      },
      isChecked: props.userSetGalleryLayout === 'default',
      onClick: () => {
        props.onUserSetGalleryLayout && props.onUserSetGalleryLayout('default');
        setFocusedContentOn(false);
      },
      iconProps: {
        iconName: 'DefaultGalleryLayout',
        styles: { root: { lineHeight: 0 } }
      },
      
    };  

    const largeGalleryOption = {
      key: 'largeGallerySelectionKey',
      text: 'Large Gallery',
      canCheck: true,
      itemProps: {
        styles: buttonFlyoutIncreasedSizeStyles
      },
      isChecked: props.userSetGalleryLayout === 'largeGallery',
      onClick: () => {
        props.onUserSetGalleryLayout && props.onUserSetGalleryLayout('largeGallery');
        setFocusedContentOn(false);
      },
      iconProps: {
        iconName: 'LargeGalleryLayout',
        styles: { root: { lineHeight: 0 } }
      }
    };

    galleryOptions.subMenuProps?.items?.push(largeGalleryOption);
    galleryOptions.subMenuProps?.items?.push(galleryOption);
    moreButtonContextualMenuItems.push(galleryOptions);
  }
  const customDrawerButtons = useMemo(
    () =>
      generateCustomCallDesktopOverflowButtons(
        onFetchCustomButtonPropsTrampoline(typeof props.callControls === 'object' ? props.callControls : undefined),
        typeof props.callControls === 'object' ? props.callControls.displayType : undefined
      ),
    [props.callControls]
  );

  customDrawerButtons['primary'].slice(CUSTOM_BUTTON_OPTIONS.MAX_PRIMARY_DESKTOP_CUSTOM_BUTTONS).forEach((element) => {
    moreButtonContextualMenuItems.push({
      itemProps: {
        styles: buttonFlyoutIncreasedSizeStyles
      },
      ...element
    });
  });

  customDrawerButtons['secondary']
    .slice(CUSTOM_BUTTON_OPTIONS.MAX_SECONDARY_DESKTOP_CUSTOM_BUTTONS)
    .forEach((element) => {
      moreButtonContextualMenuItems.push({
        itemProps: {
          styles: buttonFlyoutIncreasedSizeStyles
        },
        ...element
      });
    });

  customDrawerButtons['overflow'].forEach((element) => {
    moreButtonContextualMenuItems.push({
      itemProps: {
        styles: buttonFlyoutIncreasedSizeStyles
      },
      ...element
    });
  });

  return (
    <MoreButton
      {...props}
      data-ui-id="common-call-composite-more-button"
      strings={moreButtonStrings}
      menuIconProps={{ hidden: true }}
      menuProps={{
        items: moreButtonContextualMenuItems,
        calloutProps: {
          preventDismissOnEvent: _preventDismissOnEvent
        }
      }}
    />
  );
};
