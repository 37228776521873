import React from 'react';
import '../bannerStyle.css';
import { clearReJoinSessionData,confirmBeforeClearSessionData ,checkReJoinSessionDataExpired,getRejoinSessionUrl } from '../service/reconnectCallService';

const ReconnectBanner = (props) => {
    var expired = checkReJoinSessionDataExpired();
    var url = getRejoinSessionUrl();
 
    if(!expired){
        return (
            <>
            <div id="bannerBar">
                <div className="bannerBarContent">

                    <div>
                        You appear to have an active session <br/><br/>
                        <a id="JoinCall" href={url} >Rejoin</a> <a id="DismissCall" href="#" onClick={confirmBeforeClearSessionData}  >Dismiss</a>
                    </div>
                </div>
            </div>
            </>
        );
    }
    else{

        clearReJoinSessionData();
        return (
            <>
                <div id="clearbar"></div>   
            </>
        );
    }
};

export default ReconnectBanner;