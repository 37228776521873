import React, { useEffect, useState } from 'react';
import { SERVICE_BASE, ONEC_API_OPTIONS } from '../../globals';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getAcsToken,getAcsEndpointUrl,getAcsUserId } from '../../service/reconnectCallService';
 import  SiteOffline  from './SiteOffline'
const JoinLinkPreConnectionContainer = (props) => {

    const [isSiteUnkown, setIsSiteUnknown] = useState(false);
    const [isSiteOffline, setIsSiteOffline] = useState(false);
    const [portalGetDone, setPortalGetDone] = useState(false);
    const { callid } = useParams();
    let selectedVideoDeviceKey = props.videoDevices[0].key
    let selectedAudioDeviceKey = props.audioDevices[0].key


    useEffect(() => {    
        async function get() {
           sePortalSettings();
          let joinLink = DecodeParamToCallId();

          let consultationRequest = props.consultationRequest
          consultationRequest.consultation = {};
          consultationRequest.consultation.ConferenceNode = getAcsToken();
          consultationRequest.consultation.ChatEndpointUrl = getAcsEndpointUrl();
          consultationRequest.consultation.acsUserId = getAcsUserId();
          consultationRequest.consultation.joinUrl = joinLink;

          let createResponse = await connectToExistingConsultation(consultationRequest);
          props.onReadyToConnect(createResponse, props.portalSettings.disconnectURL, selectedVideoDeviceKey, selectedAudioDeviceKey, props.portalSettings.portalLogoUrl, props.portalSettings.portalTitle, props.portalSettings.consultationWaitingRoomLayout, props.portalSettings.patientIdentifierText, props.portalSettings.isGroup);
        }
        get();
      }, []);
      
      const sePortalSettings =() => {
          setIsSiteOffline(!props.portalSettings.isOnline);
            if (props.portalSettings.isTextOnly)
            {
               props.onSetRoomTextOnly();
            }
      
            setPortalGetDone(true);
      };

      const connectToExistingConsultation = async (data) => {
        try {
          const response = await axios.post(`${SERVICE_BASE}/consultations/JoinConsultation`, data, ONEC_API_OPTIONS);
          return response.data;
        }
        catch (ex) {
          console.log(ex)         
        }
      }

      function DecodeParamToCallId() {        
        return atob(callid);        
    }

      return (
         
        <>
           {/* <pre>API: /settings/portal... {portalSettingCallStatusText}</pre><pre>Domain: {portalSettings.rowKey}</pre>   */}           
           {(portalGetDone && isSiteUnkown) ? (
             <div>Site Unknown</div>) : <></>
           }
     
           {(portalGetDone && isSiteOffline) ?
           <SiteOffline portalSettings={props.portalSettings} /> : <></>
           }
           
           {(portalGetDone && !isSiteOffline && !isSiteUnkown) ? (
             <>
               <header className="no-nav">
                 <div className="container">
                   <div className="logo">
                     <img src={props.portalSettings.portalLogoUrl} alt={props.portalSettings.portalTitle} className="logo"></img>
                   </div>
                 </div>
               </header>
            
         <div className="center">
               <h2>Joining Consultation...</h2>
         </div>
             </>
           ) : <></>
           }
     </>
        
           
       )


}
export default JoinLinkPreConnectionContainer;